import { ScrollHeader } from "./ui/ScrollHeader";
import Screen from "./util/Screen";
import { Header } from "./ui/Header";
import { SmoothScroll } from "./ui/SmoothScroll";
import { SwitchViewport } from "./ui/SwitchViewport";
import { ColumnsCount } from "./ui/ColumnsCount";
import { accordion } from "./ui/Accordion";
import { PageTopScroll } from "./ui/PageTopScroll";
import { TableStyle } from "./ui/TableStyle";
import { pulldown } from "./ui/Pulldown";
import { hamburger } from "./ui/Hamburger";
import { deleteWpDefaultClasses } from "./util/deleteWpDefaultClasses";

// lazyload jsを作成するとき用
// import { AddClassOnScroll } from "./util/LazyImageLoader";
import { AddClassOnScroll } from "./util/AddClassOnScroll";

import { SwitchHeaderClass } from "./util/SwitchHeaderClass";
import { initSelectBox } from "./ui/select";

Screen.init();
document.addEventListener("DOMContentLoaded", () => {
  const header = document.querySelector("#js-header");

  if (header) {
    new ScrollHeader({
      header,
    });
    new Header();
  }
  //wpのデフォルトクラス削除
  deleteWpDefaultClasses();
  //768px未満でヘッダーにスマホ用クラスを付与
  SwitchHeaderClass();
  //画面内に要素が入ったら要素を表示
  AddClassOnScroll();
	//ページ内スクロール
});

window.addEventListener("load", () => {
  // 読み込み時のtransition-property: allのちらつき対策
  document.querySelectorAll(".preload").forEach((elm) => {
    elm.classList.remove("preload");
  });
  document.getElementsByTagName("body")[0].classList.remove("preload");

  // ヘッダーメニュー
  pulldown();
  hamburger();

  // スライダー
  const header = document.querySelector("#js-header");

  /**
   * アンカーリンクなどのスムーススクロール
   */
  if (header) {
    new SmoothScroll({
      header, // Default: null
      BREAK_POINT: null, // Default: 768
      DURATION: null, // Default: 1200
    });
  }

  // アコーディオン
  // const accordion = new Accordion();
  accordion();
  // const accordionGroup = document.querySelectorAll('.js-accordion-group');
  // const accordionArray = [];
  // for (let i = 0; i < accordionGroup.length; i++) {
  //   accordionArray.push(
  //     new Accordion({
  //       accordionGroup: accordionGroup[i],
  //       trigger: '.js-accordion-trigger',
  //       area: '.js-accordion-area',
  //       reference: '.js-accordion-reference'
  //     })
  //   );
  // }

  const pageTopButton = document.querySelector("#js-top");
  if (pageTopButton) {
    new PageTopScroll({
      pageTopButton,
    });
  }

  new SwitchViewport();

  /**
   * カスタムブロック関連
   */
  const columns = document.querySelectorAll(".wp-block-columns");
  for (let i = 0; i < columns.length; i++) {
    new ColumnsCount({
      columns: columns[i],
      columnsItem: ".wp-block-column",
    });
  }

  const tableList = document.querySelectorAll(".wp-block-table");
  for (let i = 0; i < tableList.length; i++) {
    new TableStyle(tableList[i]);
  }
});
// セレクトボックス
initSelectBox(document);
